const locale = 'en-US';

export function Currency({ value, maximumFractionDigits }) {
  if (isNaN(value)) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits || 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export function Percentage({ value, maximumFractionDigits }) {
  if (isNaN(value)) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0 || maximumFractionDigits,
  }).format(value);
}

export function Number({ value, maximumFractionDigits }) {
  if (isNaN(value)) {
    return null;
  }

  maximumFractionDigits =
    maximumFractionDigits === undefined ? 2 : maximumFractionDigits;
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
}

export function numberFormat(value, { maximumFractionDigits } = {}) {
  if (isNaN(value)) {
    return null;
  }

  maximumFractionDigits =
    maximumFractionDigits === undefined ? 0 : maximumFractionDigits;
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
}

export function currencyFormat(value, { maximumFractionDigits, isCompact } = {}) {
  if (isNaN(value)) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits || 0,
    minimumFractionDigits: 0,
    notation: isCompact ? 'compact' : 'standard',
  }).format(value);
}

export function percentageFormat(value, { maximumFractionDigits } = {}) {
  if (isNaN(value)) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0 || maximumFractionDigits,
  }).format(value);
}
