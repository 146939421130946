import {
  Box,
  Button,
  Flex,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;
  const totalRows = table.getRowCount();

  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalRows);

  return (
    <Flex
      direction={{ sm: 'column', md: 'row' }}
      justify="space-between"
      align={{ sm: 'start', md: 'center' }}
      w={'100%'}
      px={{ md: '22px' }}
      fontSize="sm"
      color="gray.500"
      fontWeight="normal"
    >
      <Flex align="center">
        <Text me="10px" minW="max-content">
          Show rows per page
        </Text>
        <Select
          fontSize="sm"
          variant="main"
          value={pageSize}
          onChange={(e) => {
            const _pageSize = parseInt(e.target.value);
            if (_pageSize === -1) {
              _pageSize = totalRows;
            }
            table.setPageSize(_pageSize);
          }}
        >
          <option value="7">7</option>
          <option value="21">21</option>
          <option value="-1">All</option>
        </Select>
      </Flex>
      <Flex align={'center'} gap={5}>
        <Text>{`${start} - ${end} of ${totalRows}`}</Text>
        <Button
          variant="no-effects"
          p={0}
          minW={'max'}
          onClick={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage()}
        >
          <Icon as={MdChevronLeft} boxSize={5} />
        </Button>
        <Button
          variant="no-effects"
          p={0}
          minW={'max'}
          onClick={() => table.nextPage()}
          isDisabled={!table.getCanNextPage()}
        >
          <Icon as={MdChevronRight} boxSize={5} />
        </Button>
      </Flex>
    </Flex>
  );
};

function DataTable({
  data,
  columns,
  defaultSorting,
  hasPagination,
  onRowClick,
  rowHeight,
}) {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const hoverColor = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 7 });
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getPaginationRowModel: hasPagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination,
    },
  });

  useEffect(() => {
    setSorting(defaultSorting);
  }, []);

  return (
    <Flex direction="column" w="100%">
      <Box overflowX={'auto'}>
        <Table variant={'simple'} mb={6}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const column = header.column;
                  return (
                    <Th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      cursor={column.getCanSort() ? 'pointer' : 'default'}
                      pe="10px"
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '12px', lg: '14px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row, index) => (
              <Tr
                key={`row_${index}`}
                onClick={onRowClick && (() => onRowClick(row.original))}
                h={rowHeight || 'auto'}
                cursor={!!onRowClick ? 'pointer' : 'default'}
                _hover={{
                  bg: !!onRowClick ? hoverColor : 'transparent',
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const meta = cell.column.columnDef.meta;
                  const { maxWidth, align } = meta || {};
                  return (
                    <Td
                      textAlign={align}
                      key={cell.id}
                      maxW={maxWidth}
                      wordBreak={'keep-all'}
                      fontSize={{ sm: '16px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor={borderColor}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {hasPagination && <Pagination table={table} />}
    </Flex>
  );
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  hasPagination: PropTypes.bool,
  rowHeight: PropTypes.string,
  onRowClick: PropTypes.func,
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool.isRequired,
    })
  ),
};

export default DataTable;
